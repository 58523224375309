<template>
  <div>
    <v-row class="d-flex flex-row justify-space-between  px-3">
      <!-- <v-col cols="12" md="7"> -->
      <div>
        <v-btn
          icon
          color="primary"
          @click="$router.back()"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <!-- </v-col>
      <v-col cols="12" md="5" > -->
      <div class="mx-0 px-0 mt-2">
        <v-btn
         
          color="primary"
          dark
          @click="changeEdit()"
        >
          <v-icon left>{{ tab.icon }}</v-icon>
          {{ tab.title }}
        </v-btn>
        
      </div>
      <!-- </v-col> -->
    </v-row>
    <v-row class="mt-7">
      <v-col cols="12" md="12">
        <profile-details v-if="!edit" />
        <edit-profile v-if="edit"  @changeEdit="changeEdit"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EditProfile from '../components/EditProfile.vue'
import ProfileDetails from '../components/ProfileDetails.vue'
export default {
  name: 'Profile',
  components: { ProfileDetails, EditProfile },
  data: () => ({
    edit: false
  }),
  computed: {
    tab () {
      return this.edit
        ? { title: this.$t('form_candidate_profile'), icon: 'mdi-eye' }
        : { title: this.$t('Editprofile'), icon: 'mdi-pencil' }
    }
  },
  methods: {
    changeEdit () {
      this.edit = !this.edit
    }
  }
}
</script>